#hero {
	width: 100vw;
	height: calc(100vh / 3);
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	overflow: hidden;
	// background: hsla(165, 15%, 58%, 1);
	// background: radial-gradient(
	// 	circle,
	// 	hsla(165, 15%, 58%, 1) 0%,
	// 	hsla(31, 57%, 93%, 1) 50%,
	// 	hsla(206, 46%, 37%, 1) 100%
	// );
	// background: -moz-radial-gradient(
	// 	circle,
	// 	hsla(165, 15%, 58%, 1) 0%,
	// 	hsla(31, 57%, 93%, 1) 50%,
	// 	hsla(206, 46%, 37%, 1) 100%
	// );
	// background: -webkit-radial-gradient(
	// 	circle,
	// 	hsla(165, 15%, 58%, 1) 0%,
	// 	hsla(31, 57%, 93%, 1) 50%,
	// 	hsla(206, 46%, 37%, 1) 100%
	// );
	// filter: progid(
	// 	'DXImageTransform.Microsoft.gradient( startColorstr="#84A59D", endColorstr="#F7EDE2", GradientType=1 )'
	// );

	background: hsla(31, 57%, 93%, 1);
	background: radial-gradient(
		circle,
		hsla(31, 57%, 93%, 1) 0%,
		hsla(165, 15%, 58%, 1) 50%,
		hsla(206, 46%, 37%, 1) 100%
	);
	background: -moz-radial-gradient(
		circle,
		hsla(31, 57%, 93%, 1) 0%,
		hsla(165, 15%, 58%, 1) 50%,
		hsla(206, 46%, 37%, 1) 100%
	);
	background: -webkit-radial-gradient(
		circle,
		hsla(31, 57%, 93%, 1) 0%,
		hsla(165, 15%, 58%, 1) 50%,
		hsla(206, 46%, 37%, 1) 100%
	);
	filter: progid(
		'DXImageTransform.Microsoft.gradient( startColorstr="#F7EDE2", endColorstr="#84A59D", GradientType=1 )'
	);

	video {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.hero-txt {
		background-color: rgba(0, 0, 0, 0.5); /* Black w/opacity/see-through */
		color: whitesmoke;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 2;

		h1 {
			font-family: 'Irish Grover', cursive;
			font-size: 45px;
			text-align: center;
		}
	}
}

@media only screen and (min-width: 500px) {
}
@media only screen and (min-width: 600px) {
}
@media only screen and (min-width: 900px) {
	#hero {
		video {
			object-fit: fill;
		}
	}
}
@media only screen and (min-width: 1200px) {
}
@media only screen and (min-width: 1536px) {
}
