#up-next {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;
	text-align: center;
	background: #84a59d; // firebrick
	// background: #f28482;

	h2 {
		font-size: 1rem;
		color: whitesmoke;
	}
}
