.MuiPaper-root.menu {
	width: 320px;
	max-height: fit-content;
	position: fixed;
	top: -460px;
	right: 10px;
	z-index: 3;
	padding: 20px;
	transition: all 1s ease;

	&.active {
		top: 55px;
	}

	.menu-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		// border: 2px ridge indigo;

		.icon {
			&.auth {
				color: green;
			}

			&.forgot,
			&.reset {
				color: steelblue;
			}
		}

		.desc {
			margin: 10px 0;
		}

		.response-container {
			text-align: center;

			.success,
			.error {
				margin-top: 10px;

				span {
					vertical-align: middle;
					margin-right: 2.5px;
				}
			}
		}

		.link {
			margin-top: 10px;
			cursor: pointer;
		}
	}
}

@media only screen and (min-width: 500px) {
}
@media only screen and (min-width: 600px) {
	.MuiPaper-root.menu {
		&.active {
			top: 63px;
		}
	}
}
@media only screen and (min-width: 900px) {
}
@media only screen and (min-width: 1200px) {
}
@media only screen and (min-width: 1536px) {
}
