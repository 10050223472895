#user {
	margin-bottom: 20px;

	.cover-photo-section {
		height: 180px;
		display: flex;
		justify-content: center;
		background-color: orange;
		position: relative;

		.cover-photo-container {
			width: 100%;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}

			.cover-photo-btn {
				width: inherit;
				height: 180px;
				display: none;
				position: absolute;
				left: 0;
				bottom: 0;

				.btn-icon {
					font-size: 50px;
					color: rgba(0, 0, 0, 0.6);
				}
			}

			&:hover .cover-photo-btn {
				display: block;
			}
		}

		.user-image-container {
			width: 132px;
			height: 132px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-left: var(--spacing);
			position: absolute;
			left: 0;
			bottom: -66px;

			img {
				width: 100%;
				height: 100%;
				border-radius: 50%;
				border: 4px solid white;
				background-color: grey;
				object-fit: cover;
				object-position: center;
			}

			.profile-pic-btn {
				position: absolute;
				width: inherit;
				height: inherit;
				display: none;

				.btn-icon {
					font-size: 50px;
					color: rgba(0, 0, 0, 0.6);
				}
			}

			&:hover .profile-pic-btn {
				display: block;
			}
		}
	}

	.user-details-container {
		display: flex;
		flex-direction: column;
		align-items: self-start;
		padding: 0 var(--spacing);
		margin-top: 70px;

		// .followers-container {
		// 	a {
		// 		color: var(--border);
		// 		margin-right: 15px;
		// 		text-decoration: none;
		// 	}

		// 	.value {
		// 		font-weight: bold;
		// 		margin-right: 5px;
		// 		color: var(--paragraph);
		// 	}
		// }
	}
}

@media only screen and (min-width: 500px) {
}
@media only screen and (min-width: 600px) {
}
@media only screen and (min-width: 900px) {
}
@media only screen and (min-width: 1200px) {
}
@media only screen and (min-width: 1536px) {
}
