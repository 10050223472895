.slide {
	width: 90%;
	align-self: center;

	.slick-arrow::before {
		color: black !important;
	}

	.pic-container {
		max-width: 500px;
		height: 300px;
		overflow: hidden;
		position: relative;

		.pic-wrapper {
			position: relative;
			height: 100%;
			width: fit-content;
			overflow: hidden;
			margin: 0 auto;

			img {
				width: 100%;
				height: 100%;
				object-fit: fill;
				display: block;
			}

			.overlay {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				padding: 10px;
				background-color: rgba(0, 0, 0, 0.4);
				color: whitesmoke;
			}
		}
	}
}
