.App {
	text-align: center;

	.row {
		display: flex;
	}

	.white-txt {
		color: whitesmoke !important;
	}

	.visibility-icon {
		color: grey;
	}

	.success {
		color: green;
	}

	.link {
		text-decoration: none;
		color: steelblue;
	}

	.error {
		color: red;
	}
}
