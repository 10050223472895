.event-template {
	display: flex;
	align-items: center;
	padding: 20px;
	border-bottom: 1px ridge var(--border);
	margin-bottom: 5px;

	.event-info-container,
	.rsvp-container,
	.host-action-container {
		display: flex;
		flex-direction: column;
	}

	.event-info-container {
		flex: 4;
		gap: 8px;

		p {
			font-size: 0.8rem;
		}
	}

	.rsvp-container {
		flex: 5;
		align-items: center;
	}

	.host-action-container {
		flex: 1;
		align-items: end;
	}
}
