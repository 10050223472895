.MuiToolbar-root {
	box-sizing: border-box;
	justify-content: space-between;
	// background: #7597bd;
	background: #33658a;
	z-index: 4;
	transition: all 1s ease-in-out;

	h3 {
		font-weight: 500;
	}

	.user-area {
		display: flex;
		justify-content: center;
		align-items: center;

		.profile-icon {
			width: 30px;
			height: 30px;
		}
	}

	.hamburger {
		width: 18px;
		height: 13px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		cursor: pointer;

		span {
			width: 100%;
			height: 2px;
			background: whitesmoke;
			transform-origin: left;
			transition: all 1s ease-in-out;
		}
	}

	.user-controls {
		display: flex;
	}

	.avatar,
	.home,
	.logout {
		margin: 0 10px;
	}

	&.active {
		background: #7d67cb; // #6d41a1

		.hamburger {
			span {
				width: 20px;
				&:first-child {
					background: whitesmoke;
					transform: rotate(35deg);
				}
				&:nth-child(2) {
					opacity: 0;
				}
				&:last-child {
					background: whitesmoke;
					transform: rotate(-35deg);
				}
			}
		}
	}
}
