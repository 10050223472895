.small-cal {
	margin-top: 10px;

	span {
		font-size: xx-small;
	}

	.cell {
		width: 20px;
		height: 20px;
		border: none;
		text-align: center;
	}
}
