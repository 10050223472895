.MuiPaper-root.user-template {
	display: flex;
	// justify-content: space-around;
	align-items: center;
	padding: 20px;

	.user-image-container,
	.user-details-container,
	.user-action-container {
		flex: 4;
		display: flex;
		justify-content: center;
	}

	.user-image-container {
		.user-image {
			width: 50px;
			height: 50px;

			img {
				width: 100%;
				height: 100%;
				border-radius: 50%;
			}
		}
	}
}
