.day-container {
	z-index: 1;
	border: 1px ridge lightgrey;
	cursor: pointer;

	.abv,
	.date {
		font-size: x-small;
		font-family: 'Open Sans', sans-serif;
	}

	.unavailable {
		border: 1px solid lightgrey;
		z-index: 1;
		cursor: not-allowed;
	}

	.cell {
		width: 35px;
		height: 35px;

		header {
			margin-top: 5px;
			margin-left: 5px;
		}

		.abv {
			margin-bottom: 1px;
		}

		.today,
		.selected-day {
			width: 16px;
			height: 16px;
			display: flex;
			justify-content: center;
			align-items: center;
			color: whitesmoke;
			border-radius: 100%;
			padding: 5px;
		}

		.today {
			background-color: steelblue;
		}

		.selected-day {
			background-color: rgba(128, 0, 128, 0.5);
		}

		.day-event {
			width: inherit;
			outline: none;
			border: none;
			border-radius: 30px;
			margin-bottom: 2px;
			padding: 2px 5px;
			z-index: 5;
			cursor: pointer;

			.btn-txt {
				font-size: xx-small;
				color: whitesmoke;
				overflow-x: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;

				&.scrolled:hover {
					text-indent: -54px;
					transition: text-indent 5s linear;
				}
			}
		}
	}
}
@media only screen and (min-width: 350px) {
	.day-container {
		.cell {
			width: 48px;
			height: 48px;
		}
	}
}
@media only screen and (min-width: 400px) {
	.day-container {
		.cell {
			width: 50px;
			height: 50px;
		}
	}
}
@media only screen and (min-width: 500px) {
	.day-container {
		.cell {
			width: 54px;
			height: 54px;
		}
	}
}
@media only screen and (min-width: 900px) {
}
@media only screen and (min-width: 1200px) {
}
@media only screen and (min-width: 1536px) {
}
