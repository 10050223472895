#profile {
	height: fit-content;
	min-height: calc(100vh - 56px);
	margin-top: 57px;
	display: flex;
	flex-direction: column;

	#profile-container {
		width: 100vw;
		max-width: 1440px;
		align-self: center;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		position: relative;

		.greeting-container {
			position: absolute;
			top: 15px;
			left: 15px;
			z-index: 5;
			background-color: rgba(0, 0, 0, 0.8);
			color: whitesmoke;
			padding: 10px;
			border-radius: 10px;
		}
	}
}

@media only screen and (min-width: 600px) {
	#profile {
		min-height: calc(100vh - 64px);
		margin-top: 64px;
	}
}

@media only screen and (min-width: 700px) {
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}
