:root {
	--brand: rgb(51, 100, 138);
	--brand-shadow: rgba(51, 100, 138, 0.2);
	--brand-alt: #7d67cb;
	--brand-alt-shadow: rgba(125, 103, 203, 0.2);
	--border: #72757e;
	--heading: whitesmoke;
	--paragraph: #94a1b2;
	--highlight: #2cb67d;
	--highlight-shadow: rgba(44, 182, 125, 0.2);
	--muted: rgba(255, 255, 255, 0.34);
	--muted-shadow: rgba(255, 255, 255, 0.06);
	--reply: #4fc4cf;
	--reply-shadow: rgba(79, 196, 207, 0.2);
	--hover: #86ba90;
	--elevation: 0px 6px 6px -3px rgba(0, 0, 0, 0.2),
		0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
	--spacing: 15px;
	--error: crimson;
}

* {
	margin: 0;
	padding: 0;
	scroll-snap-align: start;
	box-sizing: border-box;
	scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none;
	}

	input[type='number']::-webkit-inner-spin-button,
	input[type='number']::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* For Firefox */
	input[type='number'] {
		-moz-appearance: textfield;
	}
}

body {
	margin: 0;
	font-family: 'Roboto', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	scroll-behavior: smooth;
	scroll-snap-type: y mandatory;
}
