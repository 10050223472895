.guest-list {
	max-width: 35%;

	header {
		margin: 10px 0;
		text-align: center;
	}

	.container {
		width: inherit;
		position: relative;

		img {
			width: 100%;
			max-height: 422px;
		}

		.overlay {
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			padding: 10px;
			display: flex;
			flex-direction: column;
			text-align: center;
			overflow-y: scroll;

			span {
				font-size: xx-small;
			}

			.current-events {
				width: 100%;
				margin-bottom: 10px;
				display: flex;
				flex-direction: column;

				.details {
					margin-bottom: 5px;
					font-size: 10px;
				}
			}
		}
	}
}
@media only screen and (min-width: 500px) {
	.guest-list {
		.container {
			.overlay {
				padding: 15px;
				span {
					font-size: x-small;
				}
			}
		}
	}
}
@media only screen and (min-width: 600px) {
	.guest-list {
		.container {
			.overlay {
				padding: 20px;
				span {
					font-size: small;
				}
			}
		}
	}
}
@media only screen and (min-width: 700px) {
	.guest-list {
		.container {
			.overlay {
				padding: 30px;
				padding-top: 20px;
				span {
					font-size: medium;
				}
			}
		}
	}
}
@media only screen and (min-width: 800px) {
}
@media only screen and (min-width: 900px) {
}
@media only screen and (min-width: 1200px) {
}
@media only screen and (min-width: 1536px) {
}
