.schedule {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 15px 0;
}
@media only screen and (min-width: 600px) {
	.schedule {
		padding: 15px;
	}
}
@media only screen and (min-width: 700px) {
	.schedule {
		flex-direction: row;
		justify-content: space-evenly;
		align-items: center;
	}
}
@media only screen and (min-width: 900px) {
}
@media only screen and (min-width: 1200px) {
}
@media only screen and (min-width: 1536px) {
}
