.footer {
	height: fit-content;
	background: var(--brand);
	color: var(--heading);
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 20px;
	transition: all 1s ease-in-out;

	.contact,
	.info {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.icon {
			margin-right: 5px;
			vertical-align: middle;
		}
	}

	.contact {
		.email {
			margin-bottom: 5px;
		}
	}

	.info {
		.policy {
			margin-bottom: 5px;
		}
	}

	.content,
	.divider {
		display: none;
	}

	&.active {
		background-color: var(--brand-alt);
	}
}

@media only screen and (min-width: 500px) {
}
@media only screen and (min-width: 600px) {
}
@media only screen and (min-width: 900px) {
	.footer {
		flex-direction: row;
		justify-content: space-around;
		align-items: center;

		.contact,
		.info {
			align-items: flex-start;
		}

		.content,
		.divider {
			display: block;
		}

		.content {
			font-size: unset;
			font-weight: 400;
		}

		.divider {
			border-bottom: 2px solid #fef6e4;
			width: 60%;
			margin: 10px 0;
			align-self: flex-start;
		}
	}
}
@media only screen and (min-width: 1200px) {
}
@media only screen and (min-width: 1536px) {
}
