.memories {
	min-height: calc(100vh / 4);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background: #f7ede2; // peachpuff
	padding: 20px;
	text-align: center;

	.memory-container {
		display: flex;
		justify-content: center;
		margin-top: 10px;

		&.empty {
			height: 180px;
			align-items: center;
		}
	}
}
